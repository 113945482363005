import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import associationLogo from "assets/images/home-images/associationLogoV2.svg";
import { SubTitle } from "components";
import { showDiscoverMoreModal } from "utils/confirmationModals";
import IconButton from "features/Home/IconButton";
import { TradeMark } from "features/Home/TradeMark";
import { isGlobalWebsite } from "utils/helpers";

const About = forwardRef(function About(_, ref: React.Ref<HTMLDivElement>) {
  const { t } = useTranslation();
  const isGlobal = isGlobalWebsite();
  const handleDiscoverMoreModal = () => {
    showDiscoverMoreModal();
  };
  return (
    <div className=" w-full  pt-[50px] pb-10 xl:py-[60px] bg-white  px-5 md:px-10" ref={ref}>
      <div className="mx-auto w-full max-w-[1280px]  text-skin-textPrimary">
        <TradeMark
          title={
            isGlobal ? "home.global_consortium_heading" : "home.national_advanced_solution_portal"
          }
          className=" relative font-semibold text-[26px] leading-[34px] tracking-[-0.26px] xl:text-[29px] xl:leading-[44px] xl:tracking-[-0.29px;] max-w-full xl:max-w-[50%] "
        />
        <div className=" flex pt-5 flex-col xl:flex-row">
          <div className="">
            <p className=" text-skin-textBase text-[15px] md:text-base leading-[22px] md:leading-[25px] xl:leading-[26px]">
              {isGlobal
                ? t("home.established_by_the_higher_college_technology_global_content")
                : t("home.established_by_the_higher_college_technology")}
            </p>

            <IconButton
              className="!bg-skin-primary02 btn space-x-[10px] rtl:space-x-reverse !leading-[22px] !text-[16px] max-[1023px]:!text-[15px] md:!leading-[26px] !font-medium !text-base !border-skin-primary02 !px-3 !py-2 !h-auto !min-h-max mt-5 !text-skin-textPrimary normal-case hover:!bg-skin-primary hover:!text-white rounded-md"
              title={t("home.discover_more")}
              name="morePlus"
              handleClick={handleDiscoverMoreModal}
            />
          </div>
          {/* <div className=" w-full h-[1px] xl:my-[0px]  xl:h-[126px] xl:w-[1px] !border-l max-[1280px]:!border-b max-[1280px]:!border-l-0 border-skin-primary01  my-10 xl:mr-[60px] xl:ml-[50px] xl:!-mt-[6px]" /> */}

          <div className=" whitespace-nowrap lg:min-w-[450px] xl:min-w-[470px] mt-10 xl:-mt-[104px] lg:ltr:ml-14 lg:rtl:mr-14 xl:ltr:ml-44 xl:rtl:mr-44 lg:rtl:ml-0 lg:rtl:mrl-0">
            <SubTitle
              title={t("home.in_association_with")}
              className=" !justify-start text-skin-textBase font-semibold text-[15px] xl:text-base leading-[22px] md:leading-[26px] mb-3"
            />
            <div className="">
              <img alt="Company logo" src={associationLogo} className="  w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default About;
